<template>
  <div class="vue-nav-wrap d-block d-md-flex">

    <template v-if="(revenueCatUtil && revenueCatUtil.isPro() !== true) || (revenueCatUtil == null)">
      <a href="/purchases" class="btn btn-info btn-sm d-block d-md-inline my-2 mx-2">GET HiiKER PRO+</a>
    </template>

    <template v-if="user">
      <span class="mx-2 d-none d-lg-block">
        <div class="dropdown">
          <a aria-expanded="false" data-bs-toggle="dropdown" class="dropdown-toggle" type="button">
            <template v-if="revenueCatUtil && revenueCatUtil.isPro() === true">
              <img class="PRO-tag" src="../img/PRO-PLUS.png" alt="User pro status indicator." width="35"/>
            </template>
            <img alt="User Profile Picture"
                class="rounded-circle img-fluid nav-profile-img"
                :src="user.avatar_url"
                title="Your profile picture"
                 width="60"
                 height="60">
          </a>
          <a aria-expanded="false" data-bs-toggle="dropdown" class="badge badge-pill badge-primary dropdown-toggle" type="button">
            <span class="material-icons">arrow_drop_down</span>
          </a>
          <div aria-labelledby="dropdownMenuButton" class="dropdown-menu bg-dark">
            <a class="dropdown-item d-flex align-items-center" href="/user/profile">
              <span class="material-icons mr-2">account_circle</span>
              Profile
            </a>
            <a v-if="user.admin" class="dropdown-item d-flex align-items-center" href="/admin">
              <span class="material-icons mr-2">admin_panel_settings</span>
              Admin
            </a>
            <a class="dropdown-item d-flex align-items-center" href="/logout">
              <span class="material-icons mr-2">logout</span>
              Logout
            </a>
          </div>
        </div>
      </span>

    </template>
    <template v-else>
      <a href="/login" class="btn btn-secondary btn-sm d-block d-md-inline my-2 mx-2">LOGIN</a>
    </template>
    <LoadingDialog :show-loading-dialog="loading"/>
  </div>
</template>

<script lang="ts">

// @ts-ignore
import axiosClient from '../utils/helpers/axios_client';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { RevenueCatUtil } from '../utils/models/RevenueCatUser';
import LoadingDialog from '../utils/shared_components/LoadingDialog.vue';

export default {
  data() {
    return {
      user: null,
      revenueCatUtil: null,
      loading: false,
      intervalId: null,
    };
  },
  async beforeCreate() {
    const { data } = await axiosClient.get('/user/current');
    // @ts-ignore
    this.user = data;
    if (window.user == null) {
      window.user = data;
    }
    window.bus.$on('user:updated', async () => {
      const response = await axiosClient.get('/user/current');
      // @ts-ignore
      this.user = response.data;
      // @ts-ignore
      window.user = this.user;
    });
    try {
      // @ts-ignore
      this.revenueCatUtil = await RevenueCatUtil.get();
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(e);
    }
    const currentUrl = new URL(window.location.href);
    const redirectStatus = currentUrl.searchParams.get('redirect_status');

    if (redirectStatus === 'succeeded') {
      // @ts-ignore
      this.loading = true;
      // @ts-ignore
      await this.checkApi();
      // @ts-ignore
      this.intervalId = setInterval(this.checkApi, 5000);
    }
  },
  components: {
    LoadingDialog,
  },
  methods: {
    async checkApi() {
      // @ts-ignore
      if (!this.loading) {
        return;
      }
      // @ts-ignore
      this.revenueCatUtil = await RevenueCatUtil.get();
      // @ts-ignore
      if (this.revenueCatUtil && (this.revenueCatUtil.isPro() || this.revenueCatUtil.isProPlus())) {
        // @ts-ignore
        this.loading = false;
        // @ts-ignore
        clearInterval(this.intervalId);
        // @ts-ignore
        this.$toasted.show('PURCHASE SUCCESSFUL', {
          position: 'top-right',
          icon: 'done',
          duration: 10000,
          containerClass: 'paymentSuccessToast',
          closeOnSwipe: true,
          singleton: true,
        });
        console.log('Im pro');
      } else {
        console.log('Not pro yet');
      }
    },
    async isPro() {
      // @ts-ignore
      return this.revenueCatUtil.isPro();
    },
    async isProPlus() {
      // @ts-ignore
      return this.revenueCatUtil.isProPlus();
    },
  },
};
</script>

<style scoped>

.PRO-tag{
  position: absolute;
  top: 2px;
  left: 27px;
  box-shadow: 5px 4px 5px 0px rgb(0 0 0 / 30%);
  -webkit-box-shadow: 5px 4px 5px 0px rgb(0 0 0 / 30%);
  -moz-box-shadow: 5px 4px 5px 0px rgba(0,0,0,0.3);
}

@media (max-width: 720px) {
  .vue-nav-wrap{
    width: 100%;
  }
}

</style>
